@import '../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
$default_gradient_start: #b0bec9;
$default_gradient_end: #c5cfd8;
$default_background: linear-gradient(90deg, $default_gradient_start 0%, $default_gradient_end 100%);
$default_mobile_gradient_start: #687200;
$default_mobile_gradient_end: #254200;
$default_mobile_background: linear-gradient(
    225deg,
    $default_mobile_gradient_start 0%,
    $default_mobile_gradient_end 100%
);
$slides: (
    'gaming': (
        'gradient_start': #0a1d2f,
        'gradient_end': #0c2338,
    ),
    'pizzeria': (
        'gradient_start': #ebc064,
        'gradient_end': #f7d474,
    ),
    'real-estate': (
        'gradient_start': $default_gradient_start,
        'gradient_end': $default_gradient_end,
    ),
    'beauty': (
        'gradient_start': #e7bdbe,
        'gradient_end': #efcdcd,
    ),
);
$field_color: rgba($white, .8);
$field_disabled_border_color: rgba($white, .1);
$grid_gap: 31px;
$grid_col_width: calc(50% - #{$grid_gap / 2});
$scroll_arrow_size: 35px;

@mixin slider-backgrounds($class_name, $slide_name, $prev: false) {
    $slide: map-get($slides, $slide_name);
    $gradient_start: map-get($slide, 'gradient_start');
    $gradient_end: map-get($slide, 'gradient_end');
    $pseudo: 'after';

    @if $prev {
        $pseudo: 'before';
    }

    &.#{$class_name}:#{$pseudo} {
        background-image: linear-gradient(225deg, $gradient_start 0%, $gradient_end 100%);
    }
}

.intro-header-container {
    padding-top: 80px;
    position: relative;
    overflow: hidden;

    @include media-query-grid(sm) {
        overflow: auto;
    }

    &::before,
    &::after {
        content: ' ';
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
    }

    &::before {
        background-image: $default_mobile_background;
    }

    @include media-query-grid(sm) {
        &::after {
            z-index: -1;
            opacity: 0;
        }

        &.animating::after {
            opacity: 1;
            transition: opacity ease-out .5s;
        }

        $keys: map-keys($slides);

        @for $i from length($keys) through 1 {
            $name: nth($keys, $i);

            @include slider-backgrounds($name, $name, false);

            $prev_index: $i - 1;

            @if $prev_index == 0 {
                $prev_index: 4;
            }

            $prev_name: nth($keys, $prev_index);

            @include slider-backgrounds($name, $prev_name, true);
        }
    }

    .inner {
        position: relative;
        padding-bottom: 64px;

        @include media-query-grid(md) {
            padding-bottom: 115px;
        }
    }

    .upper {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        text-align: center;

        @include media-query-grid(md) {
            flex-direction: row;
            align-items: center;
        }

        .content-wrapper {
            max-width: 410px;
            margin: 0 auto;

            @include media-query-grid(sm) {
                max-width: 100%;
            }

            @include media-query-grid(md) {
                width: $grid_col_width;
            }

            .intro-header-content {
                margin-top: 24px;

                @include media-query-grid(sm) {
                    margin-top: 32px;
                    max-width: 472px;
                }

                @include media-query-grid(md) {
                    margin-top: 0;
                    max-width: 371px;
                    text-align: left;
                }

                @include media-query-grid(lg) {
                    width: 93.31%;
                    max-width: 499px;
                }
            }

            .intro-header-content.start-brief {
                display: flex;
                flex-direction: column;

                .total-rating {
                    color: $white;
                    margin-top: 80px;
                }
            }

            h1 {
                color: color($lime, 700);
                font-weight: 400;
                padding-bottom: 20px;
                font-size: 20px;
                line-height: 28px;

                @include media-query-grid(sm) {
                    color: $white;
                    font-size: 24px;
                    line-height: 32px;
                }
            }

            h2 {
                color: $white;
                padding-bottom: 20px;
                line-height: 40px;

                @include media-query-grid(sm) {
                    line-height: 48px;
                }

                @include media-query-grid(lg) {
                    font-size: 48px;
                    line-height: 55px;
                }
            }

            form {
                display: flex;
                flex-direction: column;
                align-items: stretch;
            }

            .intro-header-btn {
                margin-top: 25px;
                color: color($grey, 1200);

                @include media-query-grid(sm) {
                    align-self: center;
                }

                @include media-query-grid(md) {
                    align-self: flex-start;
                }
            }

            .intro-header-input {
                background-color: transparent;
                padding: 15px 0;
                border-width: 0;
                border-color: $white;
                border-bottom-width: 1px;
                border-radius: 0;
                font-weight: 300;
                font-size: 20px;
                text-align: center;
                box-shadow: none;

                @include media-query-grid(sm) {
                    font-size: 24px;
                    line-height: 32px;
                }

                @include media-query-grid(md) {
                    text-align: left;
                }

                @include media-query-grid(lg) {
                    font-size: 32px;
                    line-height: 40px;
                }

                &,
                &::placeholder {
                    color: $field_color;
                }

                &:disabled {
                    border-color: $field_disabled_border_color;
                }
            }
        }
    }

    .image-wrapper {
        display: none;
        width: 100%;
        margin-top: 38px;
        opacity: 0;
        transition: opacity .2s ease-out;

        @include media-query-grid(sm) {
            display: block;
        }

        @include media-query-grid(md) {
            width: $grid_col_width;
            margin-top: 0;
            padding-left: 31px;
        }
    }

    &.animating .image-wrapper {
        opacity: 1;
        transition: opacity .7s ease-in .3s;
    }

    .seller-wrapper {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: color($grey, 1100);
        margin-top: 20px;

        @include media-query-grid(sm) {
            text-align: right;
        }

        span:first-child {
            padding-right: 10px;
            color: color($grey, 800);
        }

        .intro-header-avatar {
            margin-right: 5px;
        }
    }

    &.gaming .seller-wrapper {
        color: $white;
    }

    .scroll-arrow {
        display: none;
        background: transparent url('./images/arrow.svg') no-repeat;
        width: $scroll_arrow_size;
        height: $scroll_arrow_size;
        border: 0;
        position: absolute;
        bottom: 46px;
        left: calc(50% - #{$scroll_arrow_size / 2});
        transform: translateX(-50%);
        animation: arrow-bounce 4s 4s infinite;

        @include media-query-grid(md) {
            display: block;
        }
    }
}

@keyframes arrow-bounce {
    0%,
    10%,
    25%,
    40%,
    100% {
        transform: translateY(0);
    }

    20% {
        transform: translateY(-20px);
    }

    30% {
        transform: translateY(-10px);
    }
}
