@import './mixins';

.header-example-gaming picture {
    &:nth-child(1) img {
        left: 5%;
        width: 47%;
        height: auto;
        top: 3%;

        @include add-animation(1.97);
    }

    &:nth-child(2) img {
        width: 49.2%;
        height: auto;
        left: 43.4%;
        top: 3%;

        @include add-animation(1.89);
    }

    &:nth-child(3) img {
        width: 47%;
        height: auto;
        top: 37%;
        left: 37%;

        @include add-animation(2.39);
    }
}
