@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
@import '../../../../../styles/landing-page';

.wrapper {
    display: grid;
    grid-template-rows: min-content min-content 1fr;
    align-items: start;
    grid-gap: 16px;

    .image {
        width: 100%;
        border-radius: 3px;
        max-height: 174px;
        object-fit: cover;
    }

    .title-link {
        text-decoration: none;

        .title {
            @include title-4;
        }
    }

    .text {
        @include media-query-grid-reverse(md) {
            display: none;
        }
    }

    .link {
        color: color($grey, 1200);
        font-weight: 600;
        text-decoration: underline;

        .link-icon {
            margin-left: 12px;
            vertical-align: text-bottom;
            fill: color($grey, 1200);
        }
    }
}
