@import '../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
.wrapper {
    position: relative;
    padding: 56.25% 0 0;

    .container > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .preview {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        transition: opacity .2s .5s;
        cursor: pointer;

        &.hidden {
            opacity: 0;
            pointer-events: none;
        }

        .preview-image {
            width: 100%;
        }

        .preview-btn {
            display: grid;
            width: 56px;
            height: 56px;
            margin: -28px 0 0 -28px;
            position: absolute;
            top: 50%;
            left: 50%;
            background-color: $white;
            border-radius: 50%;
            box-shadow: $box_shadow_z1;
            transition: .2s;

            @include media-query-grid(sm) {
                width: 90px;
                height: 90px;
                margin: -45px 0 0 -45px;
            }

            .preview-btn-icon {
                margin: auto;
                fill: color($grey, 1200);
                width: 24px !important;
                height: 24px !important;

                @include media-query-grid(sm) {
                    width: 38px !important;
                    height: 38px !important;
                }
            }
        }

        &:hover {
            .preview-btn {
                transform: scale(1.2);
            }
        }
    }
}
