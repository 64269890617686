@import './mixins';

.header-example-real-estate picture {
    &:nth-child(1) img {
        left: 6%;
        width: 18%;
        height: auto;
        top: 6%;

        @include add-animation(2.23);
    }

    &:nth-child(2) img {
        width: 39%;
        height: auto;
        left: 18.5%;
        top: 17.4%;

        @include add-animation(1.86);
    }

    &:nth-child(3) img {
        width: 33.5%;
        height: auto;
        left: 50.3%;
        top: 8%;

        @include add-animation(2.75);
    }

    &:nth-child(4) img {
        width: 28%;
        height: auto;
        left: 37.7%;
        top: 25.5%;

        @include add-animation(3);
    }

    &:nth-child(5) img {
        width: 30.2%;
        height: auto;
        top: 60.9%;
        left: 15%;

        @include add-animation(1.7);
    }

    &:nth-child(6) img {
        width: 38.8%;
        height: auto;
        top: 32%;
        left: 55%;

        @include add-animation(2.2);
    }
}
