@import '../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
.wrapper {
    display: inline-flex;
    flex-direction: column;
    height: min-content;
}

.stars-wrapper {
    display: flex;
    padding-bottom: .25em;

    &.readonly {
        .star-icon {
            cursor: default;
        }
    }
}

.star-icon {
    display: flex;
    cursor: pointer;

    &:not(:last-of-type) {
        padding-right: .275em;
    }

    svg {
        fill: currentcolor;
        height: 1em;
        width: 1em;
    }
}

.labels-wrapper {
    display: flex;
    justify-content: space-between;
    font-size: .375em;
    color: color($grey, 800);
    height: 0;
}
