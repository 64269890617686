@import '../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
@import '../../../../styles/landing-page';

$transition_duration: .4s;
$transition_timing_func: ease-out;

.wrapper {
    box-sizing: border-box;
    display: grid;
    width: 100vw;
    overflow: hidden;
    transition: background-color $transition_duration $transition_timing_func;
    position: relative;

    &.light {
        .container .text-wrapper {
            .title {
                color: color($grey, 1200);
            }

            .input .inner-input {
                color: color($grey, 1200);
                border-bottom: 1px solid color($grey, 1200);
            }

            .button {
                color: $white;
                background-color: color($grey, 1200);
            }
        }

        .scroll-button {
            background-color: color($grey, 1200);

            .down-icon {
                fill: $white;
            }
        }
    }

    @include media-query-grid(md) {
        min-height: 500px;
        max-height: 900px;
        height: 100vh;
        padding-bottom: 0;
    }

    .container {
        display: grid;
        width: 100%;
        padding-top: 120px;
        place-self: start center;
        grid-gap: 24px;

        @include media-query-grid(sm) {
            padding-top: 160px;
        }

        @include media-query-grid(md) {
            grid-template-columns: 1fr 1.5fr;
            place-self: center;
            padding-top: 0;
        }

        @include container;

        .text-wrapper {
            display: grid;
            max-width: 400px;
            place-self: center;
            justify-items: center;
            grid-gap: 24px;
            text-align: center;

            @include media-query-grid(md) {
                place-self: center start;
                justify-items: start;
                text-align: start;
            }

            .title {
                color: $white;
                transition: color $transition_duration $transition_timing_func;

                @include title-1;
            }

            .input {
                width: 100%;

                .inner-input {
                    background-color: transparent;
                    border-radius: 0;
                    border: none;
                    border-bottom: 1px solid $white;
                    color: $white;
                    font-size: 24px;
                    line-height: 32px;
                    padding: 8px 0;
                    transition:
                        color $transition_duration $transition_timing_func,
                        border $transition_duration $transition_timing_func;

                    @include media-query-grid-reverse(md) {
                        text-align: center;
                    }
                }
            }

            .button {
                color: color($grey, 1200);
                background-color: $white;
                transition:
                    color $transition_duration $transition_timing_func,
                    background-color $transition_duration $transition_timing_func;
            }
        }

        .image-wrapper {
            display: grid;

            .image {
                margin: auto;
                width: 100%;
                object-fit: contain;
                aspect-ratio: 4 / 3;
                transition-property: opacity;

                @include media-query-grid-reverse(md) {
                    max-width: 600px;
                    object-fit: cover;
                    object-position: top;
                    aspect-ratio: 2 / 1;
                }

                &.in-transition {
                    opacity: 0;
                }

                &:not(.in-transition) {
                    transition-timing-function: ease-in-out;
                    opacity: 1;
                }
            }
        }
    }

    .scroll-button {
        position: absolute;
        background-color: $white;
        display: flex;
        border-radius: 50%;
        padding: 9px;
        bottom: 32px;
        left: 50%;
        transition: background-color $transition_duration $transition_timing_func;
        cursor: pointer;
        transform: translate(-50%, 0);
        animation: scroll-button-bounce 4s 4s infinite;

        @include media-query-grid(md) {
            bottom: 48px;
        }

        .down-icon {
            fill: color($grey, 1200);
            transform: rotate(-90deg);
            transition: fill $transition_duration $transition_timing_func;
        }
    }
}

@keyframes scroll-button-bounce {
    0%,
    10%,
    25%,
    40%,
    100% {
        transform: translate(-50%, 0);
    }

    20% {
        transform: translate(-50%, -50%);
    }

    30% {
        transform: translate(-50%, -25%);
    }
}
