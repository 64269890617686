@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
@import '../../../../../styles/landing-page';

.title {
    @include description-1;

    display: flex;

    @include media-query-grid(sm) {
        padding-bottom: 12px;
    }

    .number {
        font-family: 'Domaine Display', $font_domaine;
        font-size: 30px;
        padding-right: 16px;
        border-right: 1px solid;
        margin-right: 16px;
    }
}
