@import '../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
@import '../../../../styles/landing-page';

.wrapper {
    @include section;

    background-color: color($green, 1200);
    padding: 48px 0;

    @include media-query-grid(sm) {
        padding: 96px 0;
    }

    .container {
        @include container;

        .title {
            @include title-2;
            @include section-title;

            color: $white;
        }

        .tags-box {
            text-align: center;

            .tag {
                margin: 6px;
                cursor: pointer;

                @include media-query-grid-reverse(sm) {
                    padding: 2px 8px;
                    font-size: 13px;
                    margin: 4px 2px;
                }

                &:hover {
                    background-color: color($grey, 500);
                    color: color($grey, 1200) !important;
                }
            }

            .more-tag {
                margin: 6px;
                cursor: pointer;
                background-color: transparent;

                @include media-query-grid-reverse(sm) {
                    display: block;
                    margin: 20px 0 0;
                    border: none;
                }

                @include media-query-grid(sm) {
                    border: 1px solid $white;
                }
            }
        }
    }
}
