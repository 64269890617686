@import './mixins';

.header-example-beauty picture {
    &:nth-child(1) img {
        left: 0%;
        width: 49%;
        height: auto;
        top: 8%;

        @include add-animation(2.16);
    }

    &:nth-child(2) img {
        width: 74.5%;
        height: auto;
        left: 27.5%;
        top: 4.4%;

        @include add-animation(1.91);
    }

    &:nth-child(3) img {
        width: 43.8%;
        height: auto;
        top: 39.9%;
        left: 48%;

        @include add-animation(2.03);
    }

    &:nth-child(4) img {
        width: 23%;
        height: auto;
        top: 58%;
        left: 27.5%;

        @include add-animation(1.81);
    }
}
