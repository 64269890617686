@import '../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
@import '../../../../styles/landing-page';

$base_color: color($pink, 1200);

.wrapper {
    @include section;

    background-color: $base_color;
    padding: 32px 0;
    margin-bottom: 0 !important;

    @include media-query-grid(md) {
        padding: 64px 0;
    }

    .container {
        @include container;

        display: grid;
        justify-items: center;

        .title {
            @include title-2;
            @include section-title;

            color: $white;
        }

        .subtitle {
            @include description-1;

            margin-bottom: 24px;
            color: $white;
            text-align: center;
        }

        .button {
            display: block;
            margin: 0 auto;
            color: color($grey, 1200);
        }

        .input {
            width: 100%;
            max-width: 100%;
            margin-bottom: 24px;

            @include media-query-grid(sm) {
                max-width: 350px;
            }

            .inner-input {
                background-color: transparent;
                text-align: center;
                border-radius: 0;
                border: none;
                border-bottom: 1px solid $white;
                color: $white;
                font-size: 18px;
                line-height: 26px;
                padding: 8px 0;
            }
        }
    }
}
