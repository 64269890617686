@import '../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
@import '../../../../styles/landing-page';

.wrapper {
    @include section;

    .thin-container {
        @include thin-container;

        .title {
            @include title-2;
            @include section-title;
        }

        .subtitle {
            @include description-1;
            @include section-subtitle;
        }
    }

    .content {
        @include container;

        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 40px;

        @include media-query-grid(xs) {
            grid-row-gap: 56px;
        }

        @include media-query-grid(lg) {
            grid-template-columns: 1fr 1fr;
            grid-gap: 0 88px;
            align-items: center;
        }

        .collapsed-view {
            display: block;

            @include media-query-grid(sm) {
                display: none;
            }
        }

        .non-collapsed-view {
            display: none;

            @include media-query-grid(sm) {
                display: grid;
                grid-row-gap: 40px;
            }
        }

        .answer-title {
            @include description-1;

            display: flex;
            padding-bottom: 8px;
        }

        .collapsible:not(:last-of-type) {
            border-bottom: 1px solid color($grey, 300);
        }

        .collapsible-title-wrapper {
            color: color($grey, 1100);
        }
    }

    .illustration-container {
        min-width: 100%;
        margin: 0 -#{$container_sm_padding};
        padding: 0;

        @include media-query-grid(sm) {
            margin: 0 auto;
        }

        .illustration-image {
            max-width: 100%;
            width: 100%;
            height: auto;
        }
    }
}
