@import './mixins';

.header-example-pizzeria picture {
    &:nth-child(1) img {
        left: 16%;
        width: 25%;
        height: auto;
        top: 4.5%;

        @include add-animation(2.4);
    }

    &:nth-child(2) img {
        left: 21.5%;
        width: 32.8%;
        height: auto;
        top: 22.5%;

        @include add-animation(2.7);
    }

    &:nth-child(3) img {
        width: 12.5%;
        height: auto;
        left: 0;
        top: 22.9%;

        @include add-animation(2.74);
    }

    &:nth-child(4) img {
        width: 32.5%;
        height: auto;
        top: 39.9%;
        left: 3.7%;

        @include add-animation(2.17);
    }

    &:nth-child(5) img {
        width: 16%;
        height: auto;
        top: 77%;
        left: 37%;

        @include add-animation(2.32);
    }

    &:nth-child(6) img {
        width: 32%;
        height: auto;
        top: 24%;
        left: 57%;

        @include add-animation(2.72);
    }

    &:nth-child(7) img {
        width: 43.5%;
        height: auto;
        top: 37.1%;
        left: 55.7%;

        @include add-animation(1.8);
    }
}
