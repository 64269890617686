@import '../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
@import '../../../../styles/landing-page';

.wrapper {
    @include section;

    .thin-container {
        @include thin-container;

        max-width: 676px;

        .title {
            @include title-2;
            @include section-title;
        }

        .subtitle {
            @include description-1;
            @include section-subtitle;

            text-align: center;
        }
    }

    .container {
        @include container;

        .reasons {
            display: grid;
            grid-template-rows: auto;
            grid-gap: 24px;
            justify-content: center;
            margin: 0 auto;

            @include media-query-grid(sm) {
                max-width: 720px;
            }

            @include media-query-grid(md) {
                max-width: 1100px;
                grid-gap: 40px 64px;
                grid-template-columns: repeat(3, 1fr);
                justify-items: center;
                align-items: baseline;
            }

            @include media-query-grid(lg) {
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 80px;
            }

            .reason {
                display: grid;
                grid-column-gap: 16px;
                grid-template:
                    'illustration reason-title' auto
                    'illustration description' auto
                    / 64px auto;

                @include media-query-grid(md) {
                    grid: 1fr / auto;
                    grid-column-gap: 20px;
                    max-width: 281px;
                }
            }

            .illustration {
                grid-area: illustration;

                @include media-query-grid(md) {
                    grid-area: auto;
                    margin-bottom: 24px;
                }

                .illustration-image {
                    width: 64px;
                }
            }

            .reason-title {
                @include title-3;

                grid-area: reason-title;
                padding-bottom: 8px;

                @include media-query-grid(md) {
                    grid-area: auto;
                }
            }

            .description {
                grid-area: description;

                @include media-query-grid(md) {
                    grid-area: auto;
                }
            }
        }
    }
}
