@import '../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
@import '../../../../styles/landing-page';

.wrapper {
    @include section;

    .container {
        @include thin-container;

        .title {
            @include title-2;
            @include section-title;
        }

        .collapsible {
            &:not(:last-of-type) {
                border-bottom: 1px solid color($grey, 300);
            }

            .question-title {
                font-size: 16px;
                line-height: 24px;
                color: color($grey, 1200);
            }

            .answer-link {
                color: color($grey, 1200);
                font-weight: 600;
                text-decoration: underline;
            }
        }

        .collapsible-title-wrapper {
            color: color($grey, 1100);
        }
    }
}
