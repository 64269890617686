@import '../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
@import './example-beauty';
@import './example-gaming';
@import './example-pizzeria';
@import './example-real-estate';

.header-example {
    position: relative;
    display: inline-block;

    @include media-query-grid(md) {
        display: block;
    }

    img {
        position: absolute;
    }

    svg {
        max-width: 100%;
        width: 771px;

        @include media-query-grid(md) {
            width: auto;
        }
    }
}

@keyframes floating {
    0% {
        transform: translateY(0);
    }

    to {
        transform: translateY(5%);
    }
}
