@import '../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
@import '../../../../styles/landing-page';

.wrapper {
    @include section;

    .container {
        @include container;

        .title {
            @include title-2;
            @include section-title;
        }

        .logos {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-gap: 12px;
            margin-bottom: 56px;

            @include media-query-grid(sm) {
                grid-template-columns: repeat(3, minmax(0, 1fr));
                grid-gap: 20px;
                margin-bottom: 64px;
            }

            @include media-query-grid(md) {
                grid-template-columns: repeat(5, minmax(0, 1fr));
                grid-gap: 32px;
                margin-bottom: 96px;
            }

            .logo-card {
                display: flex;
                flex-direction: column;
                border-radius: 8px;
                border: 1px solid color($grey, 400);
                overflow: hidden;

                .logo-image {
                    width: 100%;
                    height: 100%;
                    aspect-ratio: 1;
                    object-fit: cover;
                }

                .by-block {
                    padding: 16px 20px;
                    font-size: 13px;
                    font-weight: 600;
                    line-height: 20px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;

                    .author {
                        color: color($grey, 1200);
                    }
                }
            }
        }
    }
}
