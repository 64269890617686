@import '../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
@import '../../../../styles/landing-page';

.wrapper {
    @include section;

    .container {
        @include thin-container;

        .title {
            @include title-2;
            @include section-title;

            margin-bottom: 48px;
        }

        .values {
            display: grid;
            grid-template-rows: auto;
            grid-gap: 32px;
            justify-content: center;
            margin: 0 auto;

            @include media-query-grid(md) {
                grid-gap: 40px;
                grid-template-columns: repeat(3, 1fr);
                justify-items: center;
                align-items: baseline;
            }

            @include media-query-grid(lg) {
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 80px;
            }

            .value {
                display: grid;
                grid-column-gap: 16px;
                grid-template:
                    'illustration value-title' auto
                    'illustration description' auto
                    / 40px auto;

                @include media-query-grid(md) {
                    grid: 1fr / auto;
                    grid-column-gap: 20px;
                    justify-items: center;
                    max-width: 281px;
                }
            }

            .illustration {
                grid-area: illustration;

                @include media-query-grid(md) {
                    grid-area: auto;
                    margin-bottom: 16px;
                }

                @include media-query-grid(lg) {
                    margin-bottom: 24px;
                }

                .illustration-image {
                    width: 48px;

                    @include media-query-grid(md) {
                        width: 72px;
                    }
                }
            }

            .value-title {
                @include title-3;

                grid-area: value-title;
                padding-bottom: 8px;

                @include media-query-grid(md) {
                    text-align: center;
                    grid-area: auto;
                }
            }

            .description {
                grid-area: description;

                @include media-query-grid(md) {
                    grid-area: auto;
                    text-align: center;
                }
            }
        }
    }
}
