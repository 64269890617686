@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
$card_box_shadow: 0 0 11px rgba(0, 0, 0, .09), 0 0 5.52px rgba(0, 0, 0, .0543), 0 0 2.292px rgba(0, 0, 0, .03),
    0 0 .8805px rgba(0, 0, 0, .0288);

.wrapper {
    display: grid;
    grid-template-areas: 'rating' 'author' 'message' 'industry';
    grid-gap: 16px;
    max-width: 920px;
    margin: 10px auto;
    padding: 24px 36px;
    box-shadow: $card_box_shadow;
    border-radius: 8px;

    @include media-query-grid(sm) {
        padding: 72px;
        grid-template-areas: 'rating' 'message' 'author';
        grid-gap: 24px;
    }

    .rating {
        grid-area: rating;

        .rating-star {
            fill: color($green, 700) !important;
        }

        .rating-score {
            padding-left: 8px;
            font-size: 16px;
            line-height: 24px;
            color: color($grey, 1200) !important;
        }
    }

    .message {
        grid-area: message;
        position: relative;
        font-family: 'Domaine Display', $font_domaine;
        font-size: 16px;
        line-height: normal;
        font-weight: 400;
        color: color($grey, 1200);

        @include media-query-grid(sm) {
            font-size: 24px;
        }

        @include media-query-grid(md) {
            font-size: 32px;
        }

        .quotes-icon {
            position: absolute;
            top: -16px;
            left: -32px;
            z-index: -1;

            @include media-query-grid-reverse(sm) {
                display: none;
            }

            @include media-query-grid(sm) {
                top: -16px;
                left: 0;
            }

            @include media-query-grid(md) {
                left: -32px;
            }
        }
    }

    .industry-mobile-details {
        grid-area: industry;
        font-size: 14px;
        color: color($grey, 1200);

        @include media-query-grid(sm) {
            display: none;
        }

        .author-country-flag {
            vertical-align: text-bottom;
            padding: 0 8px;
            margin-left: 8px;
            border-left: 1px solid color($grey, 500);
        }
    }

    .author {
        grid-area: author;
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        grid-gap: 12px;
        color: color($grey, 1200);

        @include media-query-grid(sm) {
            grid-gap: 16px;

            .avatar {
                height: 56px;
                width: 56px;
            }
        }

        .author-details {
            .author-main-details {
                font-size: 16px;

                @include media-query-grid(sm) {
                    font-size: 18px;
                }
            }

            .author-secondary-details {
                font-size: 14px;

                .author-country-flag {
                    vertical-align: text-bottom;
                    padding: 0 8px;
                    margin-left: 8px;
                    border-left: 1px solid color($grey, 500);
                }

                @include media-query-grid-reverse(sm) {
                    display: none;
                }
            }
        }
    }
}
