@import '../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
@import '../../../../styles/landing-page';

.wrapper {
    @include section;

    .container {
        @include container;

        .title {
            @include title-2;
            @include section-title;
        }

        .posts-box {
            display: none;

            @include media-query-grid(sm) {
                @include no-section-padding;

                display: grid;
                grid-template-columns: repeat(4, minmax(300px, 1fr));
                grid-gap: 32px;
                padding-bottom: 20px;
                overflow-x: auto;
            }
        }

        .posts-slider {
            @include media-query-grid(sm) {
                display: none;
            }
        }
    }
}
