@import '../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
@import '../../../styles/landing-page';

$transition_duration: .15s;
$double_transition_duration: $transition_duration * 2;

.wrapper {
    display: grid;
    grid-template-areas: 'illustration' 'steps' 'text';

    @include media-query-grid(md) {
        grid-template:
            'steps illustration' auto
            'text  illustration' auto
            / 1fr 1fr;
    }

    .steps {
        display: grid;
        justify-self: start;
        align-self: end;
        grid-auto-flow: column;
        grid-gap: 8px;
        grid-area: steps;
        margin-bottom: 16px;

        @include media-query-grid(md) {
            margin-bottom: 40px;
        }

        .step {
            display: inline-grid;
            height: 32px;
            width: 32px;
            place-items: center;
            border-radius: 50%;
            background-color: color($grey, 300);
            color: color($grey, 900);
            font-size: 16px;
            font-weight: 700;
            cursor: pointer;
            transition: background-color $double_transition_duration, color $double_transition_duration;

            @include media-query-grid(md) {
                height: 48px;
                width: 48px;
                font-size: 24px;
            }

            &.current-step {
                background-color: color($grey, 1200);
                color: $white;
            }
        }

        .in-transition {
            opacity: 0;
        }

        :not(.in-transition) {
            opacity: 1;
        }
    }

    .text-block {
        grid-area: text;
        justify-self: start;
        transition: opacity $transition_duration;

        @include media-query-grid(md) {
            max-width: 600px;
        }

        .step-name {
            display: block;
            font-size: 14px;
            text-decoration: underline;
            color: color($grey, 1200);
            margin-bottom: 9px;

            @include media-query-grid-reverse(md) {
                display: none;
            }
        }

        .title {
            margin-bottom: 24px;

            @include title-3;
        }

        .description {
            margin-bottom: 16px;
        }
    }

    .illustration {
        display: flex;
        align-items: center;
        grid-area: illustration;
        place-self: center;
        aspect-ratio: 5 / 4;
        width: 100%;

        @include media-query-grid-reverse(md) {
            max-width: 327px;
        }

        .illustration-image {
            width: 100%;
            height: 100%;
            transition: opacity $transition_duration;

            @include media-query-grid-reverse(md) {
                margin-bottom: 16px;
            }
        }
    }
}

.hidden {
    display: none;
}
