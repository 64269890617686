@import '../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
.nav-link-make-my-logo {
    position: fixed !important;
    bottom: 24px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1001;
    border-radius: 9999px !important;
    width: 255px;
    box-shadow: $box_shadow_z5;

    @include media-query-grid(sm) {
        display: none !important;
    }
}
