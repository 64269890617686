@import '../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
@import '../../../../styles/landing-page';

.wrapper {
    @include section;

    .container {
        @include container;

        .rating {
            align-items: center;
            color: color($grey, 1200);
        }
    }
}
