@import '../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
@import '../../../../styles/landing-page';

$banner_background: #be5272;

.wrapper {
    @include section;

    background-color: $banner_background;
    padding: 40px 0;

    @include media-query-grid(sm) {
        padding: 56px 0;
    }

    .container {
        @include container;

        display: grid;
        grid-template-columns: auto;
        max-width: 100%;

        .title {
            @include section-title;

            font-size: 20px;
            line-height: 28px;
            white-space: pre-line;
            color: $white;

            @include media-query-grid(sm) {
                font-size: 28px;
                line-height: 36px;
            }
        }

        .button {
            margin: 0 auto;
            color: color($grey, 1200);
        }
    }
}
