@import '../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
.wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;

    @include media-query-grid(md) {
        align-items: flex-start;
    }
}

.rating-wrapper {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    white-space: nowrap;
}

.rating {
    color: currentcolor;
    margin-top: 4px;
}

.score-wrapper {
    display: flex;
    align-items: baseline;
    gap: 8px;
}

.score {
    font-size: 24px;
    font-family: DomaineDisplay, 'Helvetica Neue', Helvetica, Arial, sans-serif;

    @include media-query-grid(sm) {
        font-size: 32px;
    }
}

.ratings-count {
    font-size: 14px;
    line-height: 1;
}

.link {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    color: currentcolor;
    font-weight: 600;
    font-size: 14px;
    white-space: nowrap;

    &:hover {
        color: currentcolor;
    }

    svg {
        fill: currentcolor;
    }

    @include media-query-grid(sm) {
        font-size: 16px;
    }
}
