@import '../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
@import '../../../../styles/landing-page';

$banner_background: #be5272;

.wrapper {
    @include section;

    .thin-container {
        @include thin-container;

        .title {
            @include title-2;
            @include section-title;
        }

        .subtitle {
            @include description-1;
            @include section-subtitle;
        }
    }

    .container {
        @include container;

        display: grid;
        grid-template-columns: auto;

        .collapsed-view {
            display: block;

            @include media-query-grid(sm) {
                display: none;
            }
        }

        .non-collapsed-view {
            display: none;

            @include media-query-grid(sm) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 40px;
            }

            @include media-query-grid(md) {
                grid-template-columns: repeat(3, 1fr);
                grid-column-gap: 64px;
            }
        }

        .collapsible:not(:last-of-type) {
            border-bottom: 1px solid color($grey, 300);
        }

        .collapsible-title-wrapper {
            color: color($grey, 1100);
        }
    }
}
